<template>
  <div>
    <el-breadcrumb class="Bdh" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>消息管理</el-breadcrumb-item>
      <el-breadcrumb-item>系统通知</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="add">
      <el-button @click="addNotice('new','')" size="small">添加</el-button>
    </div>
    <el-table :data="list" border style="width: 100%">
      <el-table-column prop="id" v-if="false"></el-table-column>
      <el-table-column prop="content" label="内容"></el-table-column>
      <el-table-column width="180">
        <template slot-scope="scope">
          <div class="opt">
            <p>
              <el-button size="mini" type="warning" icon="el-icon-edit" @click="addNotice('bj',scope.row)">编辑</el-button>
              <el-button size="mini" type="danger" icon="el-icon-delete" @click="del(scope.row,scope.$index)">删除 </el-button>
            </p>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <p class="paging">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="current" :page-sizes="pageSizes" :page-size="PageSize" layout="total, sizes, prev, pager, next, jumper" :total="totalCount" :hide-on-single-page="value"></el-pagination>
    </p>
    <el-dialog :title="title" :visible.sync="dialogFormVisible">
      <el-form class="hdtc" label-width="170px">
        <el-form-item label="用户ID">
          <el-input type="text" style="float: left;" v-model="userid" autocomplete="off"></el-input>
          <p style="font-size: 10px;color: #606266;height: 10px;float: left;margin: 0;">如需发给指定用户则填写该用户的id</p>
        </el-form-item>
        <el-form-item label="消息内容">
          <el-input type="textarea" rows="10" style="float: left;" v-model="content" autocomplete="off" placeholder="请输入消息内容">
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false;content = ''">取 消</el-button>
        <el-button type="primary" @click="sub">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  inject: ["reload"],
  data() {
    return {
      list: [],
      current: 1, //默认显示第几页
      totalCount: 0, // 总条数，根据接口获取数据长度
      pageSizes: [20], // 个数选择器（可修改）
      PageSize: 20, // 默认每页显示的条数（可修改）
      value: false,
      dialogFormVisible: false,
      type: "",
      item: "",
      title: "",
      content: "",
      userid: "",
    };
  },
  mounted() {
    this.getdata(1);
  },
  methods: {
    getdata(page) {
      this.axios
        .get(
          "/gu/get_gu_notices?page=" +
            page +
            "&size=" +
            this.PageSize +
            "&cust_id=" +
            0
        )
        .then((res) => {
          if (res.data.code == 0) {
            this.totalCount = res.data.count;
            this.list = res.data.result;
          } else {
            console.log(res);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleSizeChange() {},
    handleCurrentChange(currentPage) {
      this.getdata(currentPage);
    },
    addNotice(type, item) {
      this.dialogFormVisible = true;
      this.type = type;
      this.item = item;
      if (type != "new") {
        this.title = "编辑消息";
        this.axios
          .get("/gu/get_gu_notice_by_id?id=" + item.id)
          .then((res) => {
            if (res.data.code == 0) {
              this.content = res.data.result.content;
            } else {
              console.log(res);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.title = "添加消息";
        this.content = "";
      }
    },
    del(row, index) {
      this.$confirm("此操作将永久删除该通知, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.axios
            .post("/gu/delete_gu_notice", this.qs.stringify({ id: row.id }))
            .then((rs) => {
              if (rs.data.code == 0) {
                this.$message({
                  message: "删除成功",
                  type: "success",
                });
                this.list.splice(index, 1);
              } else {
                this.$message(rs.data.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    sub() {
      let url = "";
      let str = "";
      let form = {
        content: this.content,
      };
      if (this.type == "new") {
        url = "/gu/insert_gu_notice";
        str = "添加成功";
        if (
          this.userid != "" ||
          this.userid != null ||
          this.userid != undefined
        ) {
          form.cust_id = this.userid;
        } else {
          form.cust_id = "0";
        }
      } else {
        url = "/gu/update_gu_notice";
        str = "修改成功";
        form.id = this.item.id;
      }
      console.log(form);
      this.axios
        .post(url, this.qs.stringify(form))
        .then((rs) => {
          if (rs.data.code == 0) {
            this.dialogFormVisible = false;
            this.$message({
              message: str,
              type: "success",
            });
            this.reload();
          } else {
            this.$message(rs.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style>
.opt a {
  cursor: pointer;
  color: green;
  margin-right: 20px;
  display: inline-block;
}

.add {
  width: 100%;
  height: 50px;
  text-align: left;
}

.hdtc {
  width: 80%;
}

.Bdh {
  height: 50px;
  width: 100%;
  line-height: 40px;
}
</style>